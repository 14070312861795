:root {
  --wasp-dark-blue: #196eaf;
  --wasp-light-blue: #6ca7df;
}

.app {
  width: 100vw;
  margin: 0 auto;
  font-size: calc(100vw * 16 / 1920);
}

.headerWrapper {
  width: 100vw;
  height: calc(100vw * 100 / 1920);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}


.footerWrapper {
  position: relative;
  z-index: 1;
  width: 100%;
}

ul {
  padding-left: 0;
}

.stopScrolling {
  overflow: hidden;
  max-height: 80vh;
}

.roman {
  counter-reset: roman;
}

.roman>li::before {
  counter-increment: roman;
  content: "(" counter(roman, lower-roman)") ";
  display: inline-block;
  width: 2em;
}




@media (max-width: 991.98px) {

  .app {
    width: 100%;
    font-size: calc(100vw * 16 / 390);
    overflow: hidden;
  }

  .headerWrapper {
    width: 100%;
    height: calc(100vw * 80 / 390);
    z-index: 16;
  }

}