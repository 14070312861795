.toast {
    width: calc(100vw * 440 / 1920);
    // height: calc(100vw * 300 / 1920);
    height: auto;
    padding: calc(100vw * 20 / 1920);
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: calc(100vw * 18 / 1920);
    overflow: hidden;
    z-index: 15;
    box-shadow:  0 0 8px 6px rgba($color: #000000, $alpha: 0.2);
    background-color: #186eb6;

    &>.pickUpInfo {
        text-align: center;
        color: white;
        margin-top: calc(100vw * 20 / 1920);
        font-size: calc(100vw * 20 / 1920);
    }

    &>.promocode {
        text-align: center;
        color: #FFCC01;
        margin-top: calc(100vw * 20 / 1920);
        font-size: calc(100vw * 40 / 1920);
    }

    &>.tipsInfo {
        text-align: center;
        color: white;
        font-size: calc(100vw * 16 / 1920);
    }

    &>.confirmBtn {
        display: flex;
        justify-content: center;
        margin-top: calc(100vw * 30 / 1920);

        &>button {
            &:hover {
                & a {
                    color: white;
                }
                border: calc( 100vw * 1 / 1920) solid white; // .0625rem solid #186EB6;
            }
        }
    }

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .toast {
        width: calc(100vw * 340 / 390);
        padding: calc(100vw * 20 / 390);
        top: 50%;
        border-radius: calc(100vw * 18 / 390);
    
        &>.pickUpInfo {
            margin-top: calc(100vw * 20 / 390);
            font-size: calc(100vw * 20 / 390);
        }
    
        &>.promocode {
            margin-top: calc(100vw * 20 / 390);
            font-size: calc(100vw * 40 / 390);
        }
    
        &>.tipsInfo {
            font-size: calc(100vw * 16 / 390);
        }
    
        &>.confirmBtn {
            margin-top: calc(100vw * 30 / 390);
    
            &>button {
                &:hover {
                    & a {
                        color: white;
                    }
                    border: calc( 100vw * 1 / 390) solid white; // .0625rem solid #186EB6;
                }
            }
        }
    
    }
}