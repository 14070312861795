.popupADBanner {
    min-width: calc(100vw * 420 / 1920);
    height: 7%;
    position: fixed;
    left: 50%;
    bottom: 4%;
    transform: translate(-50%, 0);
    z-index: 15;
    display: grid;
    grid-template-columns: 10px repeat(6, 1fr) repeat(2, 1fr) 1fr 10px;
    grid-template-areas: ". a a a a a b b b c .";
    justify-content: center;
    align-items: center;
    background-color: white;
    border: calc(100vw * 1 / 1920) solid #186eb6;
    border-radius: calc(100vw * 14 / 1920);
    box-shadow:  0 0 4px 4px rgba($color: #000000, $alpha: 0.2);

    &>button {
        grid-area: a;
        width: auto;
        padding: 0 calc(100vw * 20 / 1920);
        height: 50%;

        &:hover {
            transform: scale(1);
        }
    }

    &>span.iconAfterPickup {
        grid-area: d;
        justify-self: flex-end;
        display: inline-block;
        width: calc(100vw * 32 / 1920);
        height: calc(100vw * 32 / 1920);
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &>div.off10PercentAfterPickup {
        grid-area: a;
        justify-self: center;
        color: #186eb6;
        font-weight: 700;
        font-size: calc(100vw * 18 / 1920);
    }

    &>.countDown {
        grid-area: b;
        justify-self: center;
        color: #186eb6;
        font-weight: 700;
        font-size: calc(100vw * 18 / 1920);

        &>.remainingTime {
            font-size: calc(100vw * 12 / 1920);
            margin-right: calc(100vw * 4 / 1920);
        }
    }

    &>.closeBtn_popupBanner {
        grid-area: c;
        justify-self: center;
        width: calc(100vw * 24 / 1920);
        height: calc(100vw * 24 / 1920);
        display: flex;
        justify-content: center;
        align-items: center;
        border: calc(100vw * 1 / 1920) solid rgba($color: #186eb6, $alpha: 0.8);
        border-radius: 100%;
        cursor: pointer;
        position: relative;

        &::before {
            // content: "\292C";
            content: "X";
            color: rgba($color: #186eb6, $alpha: 0.8);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .popupADBanner {
        width: 100%;
        height: 8%;
        bottom: 0;
        background-color: #186eb6;
        border-radius: 0;
    
        &>button {
            height: 50%;
            padding: 0 calc(100vw * 20 / 390);

            &:hover {
                & a {
                    color: white;
                }
                border: calc( 100vw * 1 / 1920) solid white; // .0625rem solid #186EB6;
                transform: scale(1);
            }
        }

        &>span.iconAfterPickup {
            width: calc(100vw * 32 / 390);
            height: calc(100vw * 32 / 390);
        }

        &>div.off10PercentAfterPickup {
            color: white;
            font-size: calc(100vw * 18 / 390);
        }
    
        &>.countDown {
            color: white;
            font-size: calc(100vw * 18 / 390);

            &>.remainingTime {
                font-size: calc(100vw * 12 / 390);
                margin-right: calc(100vw * 4 / 390);
            }
        }
    
        &>.closeBtn_popupBanner {
            width: calc(100vw * 24 / 390);
            height: calc(100vw * 24 / 390);
            border: calc(100vw * 1 / 390) solid rgba($color: white, $alpha: 0.5);
    
            &::before {
                // content: "\292C";
                content: "X";
                color: rgba($color: white, $alpha: 0.5);
            }
        }
    }
}