.bookingWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  z-index: 2;
  height: 50%;
  padding: 2.0833333333vw 0;
  padding-top: 4.1666666667vw;
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #ffcc01;
}
.bookingWrapper .booking {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 2.0833333333vw 8.0208333333vw;
}
.bookingWrapper .booking > .bookingTitlebar {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.bookingWrapper .booking > .bookingTitlebar span.title {
  font-size: 2.0833333333vw;
  line-height: 3.0208333333vw;
  font-weight: 700;
  color: #000;
}
.bookingWrapper .booking > .bookingTitlebar span.subtitle {
  font-size: 1.0416666667vw;
  line-height: 2.0833333333vw;
  font-weight: 700;
  color: #000;
}
.bookingWrapper .booking > .bookingTitlebar span.icon {
  width: 3.125vw;
  height: 0.2083333333vw;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
}
.bookingWrapper .booking .bookingDetail {
  min-height: 4.6875vw;
  padding: 2.5vw 0 3.3333333333vw 0;
  position: relative;
  color: #000;
  font-size: 0.8333333333vw;
  line-height: 1.5625vw;
  font-weight: 500;
  letter-spacing: 2%;
}
.bookingWrapper .booking .bookingBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bookingWrapper .booking .bookingBtn [class*=buttonWithScale] {
  width: 10.4166666667vw;
  height: 2.6041666667vw;
  background: #000;
}
.bookingWrapper .booking .bookingBtn [class*=buttonWithScale] a {
  color: white;
}
.bookingWrapper .booking .bookingBtn [class*=buttonWithScale]:hover {
  border: 0.0520833333vw solid #000;
  background: none;
  transform: scale(1.1);
}
.bookingWrapper .booking .bookingBtn [class*=buttonWithScale]:hover a {
  color: #000;
}
.bookingWrapper .booking .bookingBtn [class*=buttonWithScale] {
  margin: 0 0.0520833333vw 0 0;
}
.bookingWrapper .booking .bookingBtn [class*=buttonWithScale]:last-child {
  margin-left: 1.5625vw;
}
.bookingWrapper .ppfPic {
  position: relative;
}
.bookingWrapper .ppfPic img {
  width: 100%;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 10%;
}

@media (max-width: 991.98px) {
  .bookingWrapper {
    grid-template-columns: auto;
    grid-template-rows: repeat(4, 1fr);
    width: 100vw;
    height: 230.7692307692vw;
    padding: 10.2564102564vw 13.3333333333vw;
    background-size: cover;
  }
  .bookingWrapper .booking {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .bookingWrapper .booking > .bookingTitlebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .bookingWrapper .booking > .bookingTitlebar span.title {
    font-size: 10.2564102564vw;
    line-height: 14.8717948718vw;
    font-weight: 700;
    color: #000;
    text-align: center;
  }
  .bookingWrapper .booking > .bookingTitlebar span.subtitle {
    font-size: 5.1282051282vw;
    line-height: 9.7435897436vw;
  }
  .bookingWrapper .booking > .bookingTitlebar span.icon {
    width: 15.3846153846vw;
    height: 1.0256410256vw;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
  }
  .bookingWrapper .booking .bookingDetail {
    min-height: 23.0769230769vw;
    padding: 16.4102564103vw 0 13.8461538462vw 0;
    position: relative;
    font-size: 4.1025641026vw;
    line-height: 7.6923076923vw;
    font-weight: 500;
    color: #000;
    letter-spacing: 2%;
    position: absolute;
    top: 88.2051282051vw;
    left: 0;
    right: 0;
  }
  .bookingWrapper .booking .bookingBtn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 153.8461538462vw;
    left: 0;
    right: 0;
  }
  .bookingWrapper .booking .bookingBtn > [class*=buttonWithScale] {
    width: 51.2820512821vw;
    height: 12.8205128205vw;
    margin: 0 0 7.6923076923vw 0;
  }
  .bookingWrapper .booking .bookingBtn > [class*=buttonWithScale]:hover {
    border: 0.2564102564vw solid white;
    background: none;
    transform: scale(1.1);
  }
  .bookingWrapper .booking .bookingBtn > [class*=buttonWithScale]:hover a {
    color: white;
  }
  .bookingWrapper .ppfPic {
    position: relative;
  }
  .bookingWrapper .ppfPic img {
    width: 100vw;
    height: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}/*# sourceMappingURL=index.module.css.map */