.header {
  width: 100%;
  height: 100%;
  padding: 1.0416666667vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.header > a {
  width: 11.4583333333vw;
  height: 2.6041666667vw;
  display: inline-block;
  position: relative;
}
.header > a > .logo {
  width: 100%;
  height: 100%;
  margin: 0 0 0 2.1875vw;
  position: absolute;
}
.header > .mobileMenu {
  display: none;
}
.header > div.tabs {
  width: 62%;
  height: 100%;
}
.header > div.tabs > ul {
  width: 100%;
  height: 100%;
  padding: 0 1.875vw 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}
.header > div.tabs > ul > li {
  height: 5.2083333333vw;
  padding: 0.5208333333vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.2s;
  margin-left: 2.0833333333vw;
}
.header > div.tabs > ul > li > a {
  font-family: "Red Hat Display", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, "PingFang HK", "Microsoft JhengHei";
  font-weight: 700;
  font-size: 0.9375vw;
}
.header > div.tabs > ul > li > a > i {
  padding: 0 0 0 0.5208333333vw;
}
.header > div.tabs > ul > li > a > i.pipe::before {
  content: "|";
  padding: 0 0.3125vw 0 0;
}
.header > div.tabs > ul > li div.aboutus {
  display: none;
  z-index: -1;
  background-color: #fff;
}
.header > div.tabs > ul > li div.service-in-header {
  display: none;
  z-index: -1;
  background-color: #fff;
}
.header > div.tabs > ul > li div.aboutmaterials {
  display: none;
  z-index: -1;
  background-color: #fff;
}
.header > div.tabs > ul > li.hasArrow:hover > a {
  color: #186eb6;
}
.header > div.tabs > ul > li.hasArrow:hover > a > i::before {
  content: "\f077";
}
.header > div.tabs > ul > li:hover > a {
  color: #186eb6;
}
.header > div.tabs > ul > li:last-child {
  width: 6.25vw;
  display: flex;
  justify-content: flex-start;
}
.header > div.tabs > .mobileMenu {
  display: none;
}
.header.mobileMenubar {
  display: none;
  transition: all 1s;
}
.header .disable {
  color: rgba(169, 169, 169, 0.8666666667) !important;
  cursor: not-allowed;
}
.header .disable a {
  color: rgba(169, 169, 169, 0.8666666667) !important;
}

.allToWhite > ul > li > a {
  color: white;
}

@media (max-width: 991.98px) {
  .header {
    padding: 5.1282051282vw;
  }
  .header > a {
    width: 38.4615384615vw;
    height: 10.2564102564vw;
  }
  .header > a > .logo {
    width: 100%;
    height: 100%;
  }
  .header > div.mobileMenu {
    width: 7.1794871795vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .header > div.mobileMenu > span.menuIcon {
    width: 7.1794871795vw;
    height: 2.5641025641vw;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
  }
  .header > div.tabs {
    display: none;
  }
  .header > .mobileMenubar {
    display: block;
  }
  .header > .backdrop, .header > .lr_box {
    display: none;
  }
  .header > .mobileMenubar {
    display: block;
  }
}/*# sourceMappingURL=index.css.map */