.body {
  width: 100%;
  background-size: 100%;
  overflow: hidden;
  background-color: rgb(240, 240, 240);
  position: relative;
  z-index: 0;
}

@media (max-width: 991.98px) {
  .body {
    overflow: hidden;
    z-index: 0;
  }
}/*# sourceMappingURL=index.css.map */