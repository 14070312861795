@import "../../variables.scss";

.body {
    // width: 85.375rem;
    width: 100%;
    // min-height: calc(100vw * 6404 / 1920); // 400.25rem;
    // background: url(/src/static/images/whole-bg.svg) center calc(100vw * 768 / 1920) no-repeat; // 48rem
    background-size: 100%;
    overflow: hidden;
    background-color: rgb(240, 240, 240);
    position: relative;
    z-index: 0;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .body {
        // min-height: calc(100vw * 8000 / 390); // 610.875rem; // 400.25rem; 
        // background: url(/src/static/images/mobile/whole-bg.svg) center calc(100vw * 767 / 390) no-repeat; // 48rem
        // background-size: 100%
        overflow: hidden;
        z-index: 0;
    }
}