.buttonWithScale {
  width: 6.3541666667vw;
  height: 2.0833333333vw;
  border: none;
  background: #FFCC01;
  border-radius: 8.3333333333vw;
  font-size: 0.9375vw;
  font-weight: 700;
  transition: all 0.4s;
  cursor: pointer;
  overflow: hidden;
}
.buttonWithScale a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.buttonWithScale:hover {
  border: 0.0520833333vw solid #186EB6;
  background: none;
  transform: scale(1.1);
}
.buttonWithScale:hover a {
  color: #186EB6;
}

.disable-for-button {
  background-color: rgba(169, 169, 169, 0.8666666667) !important;
  cursor: not-allowed !important;
}
.disable-for-button > a {
  color: rgb(82, 81, 81) !important;
}
.disable-for-button:hover {
  border: none !important;
  background: rgba(169, 169, 169, 0.8666666667) !important;
  transform: scale(1) !important;
  cursor: not-allowed !important;
}
.disable-for-button:hover a {
  color: rgb(82, 81, 81) !important;
  cursor: not-allowed !important;
}
.disable-for-button:hover a > span.bookingNextButton_icon {
  display: none !important;
}
.disable-for-button:hover a > span.bookingNextButton_text {
  left: inherit !important;
}

@media (max-width: 991.98px) {
  .buttonWithScale {
    width: 51.2820512821vw;
    height: 12.8205128205vw;
    border-radius: 12.8205128205vw;
    font-size: 4.1025641026vw;
  }
  .buttonWithScale:hover {
    border: 0.2564102564vw solid #186EB6;
  }
}/*# sourceMappingURL=index.css.map */