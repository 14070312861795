.buttonWithScale {
    width: calc( 100vw * 122 / 1920);  // 6vw; // 7.625rem;
    height: calc( 100vw * 40 / 1920); // 2.6vw; // 2.5rem;
    border: none;
    background: #FFCC01;
    border-radius: calc( 100vw * 160 / 1920); // 10rem;
    font-size: calc( 100vw * 18 / 1920); // 1.2vw; //1.125rem;
    font-weight: 700;
    transition: all .4s;
    cursor: pointer;
    overflow: hidden;

    & a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &:hover {
        & a {
            color: #186EB6;
        }
        border: calc( 100vw * 1 / 1920) solid #186EB6; // .0625rem solid #186EB6;
        background: none;
        transform: scale(1.1);
    }

}

.disable-for-button {
    background-color: #a9a9a9dd !important;
    cursor: not-allowed !important;

    &>a {
        color: rgb(82, 81, 81) !important;
    }

    &:hover {
        & a {
            color: rgb(82, 81, 81) !important;
            cursor: not-allowed !important;

            &>span.bookingNextButton_icon {
                display: none !important;
            }

            &>span.bookingNextButton_text {
                left: inherit !important;
            }
        }
        border: none !important;
        background: #a9a9a9dd !important;
        transform: scale(1) !important;
        cursor: not-allowed !important;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .buttonWithScale {
        width: calc( 100vw * 200 / 390);  // 200px
        height: calc( 100vw * 50 / 390); // 50px
        border-radius: calc( 100vw * 50 / 390); // 50px;
        font-size: calc( 100vw * 16 / 390); // 16px
    
        &:hover {
            border: calc( 100vw * 1 / 390) solid #186EB6; // .0625rem solid #186EB6;
        }
    }
}