@charset "UTF-8";
#footer {
  background-color: #F8F8F8;
  position: relative;
}
#footer .container {
  background-color: #F8F8F8;
}

#footer_new1 div.logo img {
  width: auto;
  height: 54px;
}

#footer_accordion {
  padding-top: 0rem;
  background-color: #f9f9f9;
}
#footer_accordion .card {
  border: none;
  background-color: #f9f9f9;
}
#footer_accordion .card .card-header {
  border-bottom: 0;
  padding: 0 22px;
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: RGBA(240, 240, 240, 1);
}
#footer_accordion .card .card-header .button {
  width: 100%;
  display: flex;
  padding: 2rem 0.5rem 2rem 0.5rem;
  background-color: RGBA(240, 240, 240, 1);
  border-bottom: 1px solid RGBA(50, 50, 50, 0.5);
}
#footer_accordion .card .card-header .button .label {
  flex: 1;
}
#footer_accordion .card .card-header .button .arrow .arrow1 {
  display: block;
}
#footer_accordion .card .card-header .button .arrow .arrow2 {
  display: none;
}
#footer_accordion .card .card-header .button.collapsed .arrow .arrow1 {
  display: none;
}
#footer_accordion .card .card-header .button.collapsed .arrow .arrow2 {
  display: block;
}
#footer_accordion .card .card-body {
  background-color: RGBA(240, 240, 240, 1);
}
#footer_accordion .card .card-body .content {
  font-size: 1.2rem;
  color: #676767;
  font-weight: 600;
}
#footer_accordion .card .card-body .content i {
  padding: 0.5rem;
}
#footer_accordion .card .card-body .content ul {
  list-style: none;
  text-decoration: none;
  padding: 0;
}
#footer_accordion .card .card-body .follow .content {
  display: flex;
}
#footer_accordion .card .card-body .follow .content .icon {
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
#footer_accordion .card .card-body .follow .content .icon:nth-child(1) {
  margin-left: 0;
}

.footer .container {
  margin: 0 auto;
}
.footer .sections {
  display: flex;
}
.footer .separator {
  width: 100%;
  max-width: 1200px;
  border-bottom: 2px solid #e0e0e0;
  margin-top: 1rem;
}
.footer .section {
  height: 21.375rem;
  border-right: 0px solid #e0e0e0;
  margin: 0rem;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
}
.footer .section:last-child {
  border-right: none;
  margin-right: 0;
}
.footer .section .title {
  font-size: 1.625rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}
.footer .section .content {
  font-size: 1rem;
  color: black;
}
.footer .section .content i {
  padding: 0.5rem;
}
.footer ul {
  list-style: none;
  text-decoration: none;
  padding: 0;
}
.footer ul li {
  padding: 0.25rem;
  color: #333;
}
.footer ul li .nav-link {
  padding: 0;
  text-decoration: none;
  color: #333;
}
.footer ul li a {
  color: #333;
  text-decoration: none;
}
.footer .follow .content {
  display: flex;
  flex-direction: column;
}
.footer .follow .content .icon {
  margin-top: 0.875rem;
  width: 6.25rem;
  text-align: center;
}
.footer .follow .content .icon:first-child {
  margin-top: 2.125rem;
}
.footer .follow .content .icon img {
  width: 2.5rem;
  height: 2.5rem;
}
.footer .common .copyright {
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer .common .copyright .logo img {
  width: 9.375rem;
}
.footer .common .copyright .content {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}
.footer .common .copyright .content span {
  font-weight: 700;
}
.footer .common .copyright .policy span {
  font-size: 0.75rem;
  margin: 0 1.25rem;
  margin-top: 0.5rem;
}
.footer .common .copyright .policy > span:last-child {
  margin-right: 0;
}

#footer_new1 {
  padding: 2rem 20px 1rem 20px;
  background-color: white;
  max-width: 100% !important;
  padding: 2rem;
}

#footer_new1 {
  min-height: -moz-max-content;
  min-height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

#footer_new1 div.logo-slogan {
  background-repeat: no-repeat no-repeat;
  background-size: contain;
  width: 250px;
  height: 80px;
}

@media screen and (max-width: 767px) {
  #footer_new1 {
    flex-direction: column;
    background-color: white;
  }
  #footer_new1 div.logo-footer-line {
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    width: 116px;
    height: 20px;
    margin-top: 12px;
  }
  #footer_new1 div.logo-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 120px;
  }
  #footer_new1 div.logo {
    flex: 1 1 50%;
    height: 80px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
  }
  #footer_new1 div.logo img {
    width: auto;
    height: 48px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 999px) {
  #footer_new1 {
    flex-direction: row;
  }
  #footer_new1 div.logo-footer-line {
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    width: 10px;
    height: 80px;
  }
  #footer_new1 div.logo-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 120px;
  }
  #footer_new1 div.logo {
    flex: 1 1 50%;
    height: 60px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
  }
  #footer_new1 div.logo img {
    width: auto;
    height: 54px;
  }
}
@media screen and (min-width: 1000px) {
  #footer_new1 {
    flex-direction: row;
  }
  #footer_new1 div.logo-footer-line {
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    width: 10px;
    height: 80px;
    margin: 0 12px;
  }
  #footer_new1 div.logo-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background-position: center center;
  }
  #footer_new1 div.logo {
    background-repeat: no-repeat no-repeat;
  }
}
#footer_new2 {
  padding: 3.25rem 10rem;
  background-color: RGBA(240, 240, 240, 1);
}

#footer_new2 div.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#footer_collapse_2 .content ul,
#footer_new2 .content ul {
  margin-top: 3.125rem;
}
#footer_collapse_2 .content ul li,
#footer_new2 .content ul li {
  display: flex;
  align-items: center;
  margin-top: 1.125rem;
}
#footer_collapse_2 .content ul li > img,
#footer_new2 .content ul li > img {
  margin-right: 1.25rem;
  margin-top: 0.2rem;
}

#footer_accordion div.new_follow {
  padding: 2rem 1rem 2rem 1rem;
  text-align: center;
  background-color: RGBA(240, 240, 240, 1);
}

#footer_accordion div.new_follow h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

#footer_accordion div.new_follow div.follow_group {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

#footer_new3 {
  padding: 2rem auto 2rem auto;
  background-color: RGBA(222, 222, 222, 1);
  position: relative;
  padding: 0 10rem;
}
#footer_new3 .back-to-top {
  width: 5rem;
  height: 5rem;
  background-color: #333333;
  position: absolute;
  top: -3.8rem;
  right: 2.875rem;
  border-radius: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
}
#footer_new3 .back-to-top a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
#footer_new3 .back-to-top a span {
  position: absolute;
  top: 20%;
  transition: all 0.2s;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.625rem;
  letter-spacing: 0em;
  text-align: left;
}
#footer_new3 .back-to-top a i {
  position: absolute;
  bottom: 20%;
  transition: all 0.4s;
}
#footer_new3 .back-to-top a:hover span {
  top: 24%;
}
#footer_new3 .back-to-top a:hover i {
  bottom: 24%;
}
#footer_new3 .back-to-top a:hover * {
  color: white;
}
#footer_new3 .back-to-top:hover {
  box-shadow: 0 0 1rem white;
}
#footer_new3 .back-to-top * {
  color: #FFCC00;
}
#footer_new3 .for-mobile {
  width: inherit;
}

@media (max-width: 991.98px) {
  .footer .for-mobile {
    width: 100%;
    padding: 0 7.6923076923vw;
  }
  .footer > #footer_new3 {
    padding: inherit;
  }
  .footer > #footer_new2 {
    padding: inherit;
  }
  .footer > #footer_new2 > div {
    display: block !important;
  }
  .footer .row {
    flex-direction: column !important;
  }
  .footer .section {
    height: 2.25rem;
    min-height: 2.25rem;
    overflow: hidden;
    margin: 0 0 1.375rem 0;
    transition: all 0.4s;
  }
  .footer .section > .title {
    font-family: Noto Sans TC;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.875rem;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 0.0625rem solid rgba(50, 50, 50, 0.5);
    color: #333333;
  }
  .footer .section > .title::after {
    content: "›";
    float: right;
    padding: 0.25rem 1rem;
    font-weight: 900;
    font-size: 2.2rem;
    transform: rotate(90deg);
    vertical-align: middle;
    transition: all 0.4s;
  }
  .footer .section > .content {
    display: none;
    position: absolute;
  }
  .footer .section:hover, .footer .section.hover {
    min-height: 17rem;
  }
  .footer .section:hover > .content, .footer .section.hover > .content {
    display: block;
  }
  .footer .section:hover > .content ul, .footer .section.hover > .content ul {
    margin: 0 !important;
  }
  .footer .section:hover > .title::after, .footer .section.hover > .title::after {
    transform: rotate(270deg);
    padding: 0.25rem 1rem;
  }
  .footer .section:hover:nth-child(3), .footer .section.hover:nth-child(3) {
    min-height: 8rem;
  }
  .footer .section:nth-child(2):hover {
    min-height: 13rem;
  }
  .footer .section:last-child {
    overflow: visible;
    height: auto;
  }
  .footer .section:last-child > .title {
    border-bottom: none;
    text-align: center;
    font-family: Noto Sans TC;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.875rem;
    letter-spacing: 0em;
    color: #333;
  }
  .footer .section:last-child > .title::after {
    content: none;
  }
  .footer .section:last-child > .content {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    position: static;
  }
  .footer .section:last-child > .content > .follow {
    display: flex;
    flex-direction: row !important;
  }
  .footer .section:last-child > .content > .follow > .content {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
  }
  .footer .section:last-child > .content > .follow > .content > div:first-child {
    margin-top: 14px;
  }
  .footer .section:last-child:hover {
    min-height: auto;
  }
  .footer .common .copyright {
    height: auto;
    min-height: 5rem;
    flex-direction: column;
    padding: 1.75rem 0 1.5rem 0;
  }
  .footer .common .copyright > .content {
    padding: 0.5rem;
    text-align: center !important;
    white-space: nowrap;
  }
  .footer .common .copyright > .policy {
    text-align: center !important;
  }
}/*# sourceMappingURL=index.css.map */