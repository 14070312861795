.article {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding-top: 9.375vw;
  padding-bottom: 2.0833333333vw;
}
.article > .bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
}
.article > .mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(14, 99, 170, 0.8);
}
.article > .wrapper {
  max-width: 60%;
  min-width: 60%;
}
.article > .wrapper > .prev {
  display: inline-block;
  padding-bottom: 0.4166666667vw;
  color: white;
  cursor: pointer;
}
.article > .wrapper > .prev::before {
  content: "<";
}
.article > .wrapper > .prev:last-child {
  margin-top: 1.25vw;
}
.article > .wrapper > .content {
  margin: 2.5vw 0;
  padding: 3.3333333333vw;
  border-radius: 1.875vw;
  background-color: white;
}
.article > .wrapper > .content img {
  width: 66.6% !important;
}
.article > .wrapper > .content video {
  width: 66.6% !important;
  transform: translate(25%, 0);
}
.article > .wrapper > .content > .title {
  font-size: 1.6666666667vw;
  font-weight: 700;
  font-family: "Microsoft Yahei";
}
.article > .wrapper > .content > .subtitle {
  font-size: 1.0416666667vw;
  font-weight: 600;
}
.article > .wrapper > .content > .main {
  margin-top: 1.25vw;
  font-size: 0.9375vw;
  font-weight: 500;
}
.article > .wrapper > .content p {
  text-align: center;
}
.article > .wrapper > .recommend > .holder {
  margin-bottom: 0.625vw;
  font-size: 1.0416666667vw;
  color: white;
  font-weight: 700;
}
.article > .wrapper > .recommend > .rec {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -moz-column-gap: 1.25vw;
       column-gap: 1.25vw;
}
.article > .wrapper > .recommend > .rec [class*=itemCard] {
  justify-self: center;
  width: 100%;
  height: 12.5vw;
}

@media screen and (max-width: 991.98px) {
  .article {
    padding-top: 30.7692307692vw;
    padding-bottom: 10.2564102564vw;
  }
  .article > .wrapper {
    max-width: 80%;
    min-width: 80%;
  }
  .article > .wrapper > .prev {
    padding-bottom: 2.0512820513vw;
  }
  .article > .wrapper > .prev:last-child {
    margin-top: 6.1538461538vw;
  }
  .article > .wrapper > .content {
    margin: 8.2051282051vw 0;
    padding: 6.1538461538vw;
    border-radius: 4.6153846154vw;
  }
  .article > .wrapper > .content img {
    width: 100% !important;
  }
  .article > .wrapper > .content video {
    width: 100% !important;
    transform: translate(0, 0);
  }
  .article > .wrapper > .content > .title {
    font-size: 6.1538461538vw;
  }
  .article > .wrapper > .content > .subtitle {
    font-size: 3.5897435897vw;
  }
  .article > .wrapper > .content > .main {
    margin-top: 6.1538461538vw;
    font-size: 4.1025641026vw;
  }
  .article > .wrapper > .content p {
    text-align: center;
  }
  .article > .wrapper > .content iframe {
    width: 100%;
    height: 100%;
  }
  .article > .wrapper > .recommend > .holder {
    margin-bottom: 3.0769230769vw;
    font-size: 5.1282051282vw;
  }
  .article > .wrapper > .recommend > .rec {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    -moz-column-gap: 0;
         column-gap: 0;
    row-gap: 6.1538461538vw;
  }
  .article > .wrapper > .recommend > .rec [class*=itemCard] {
    height: 51.2820512821vw;
  }
}/*# sourceMappingURL=index.module.css.map */