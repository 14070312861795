.header {
    width: 100%;    
    height: 100%;
    padding: calc( 100vw * 20 / 1920); // 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &>a {   
        width: calc( 100vw * 220 / 1920); // 30%;
        height: calc( 100vw * 50 / 1920); // 100%;
        display: inline-block;
        position: relative;

        &>.logo {
            width: 100%;
            height: 100%;
            margin: 0 0 0 calc( 100vw * 42 / 1920); // 0 0 0 4%; // not use
            position: absolute;
        }
    }

    &>.mobileMenu {
        display: none;
    }

    &>div.tabs {
        width: 62%;
        height: 100%;


        &>ul {
            width: 100%;
            height: 100%;
            padding: 0 calc( 100vw * 36 / 1920) 0 0; // 0 2% 0 0;
            display: flex;
            justify-content: flex-end;
            align-items:center;
            flex-wrap: nowrap;


            &>li {
                height: calc( 100vw * 100 / 1920); // min( calc( 100vw * 100 / 1920), 6.25rem);
                padding: calc( 100vw * 10 / 1920); // .625rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                transition: all .2s;
                margin-left: calc(100vw * 40 / 1920);

                &>a {
                    font-family: "Red Hat Display",system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,"PingFang HK","Microsoft JhengHei";
                    font-weight: 700;
                    font-size: calc( 100vw * 18 / 1920); // 1.125rem;;

                    &>i {
                        padding: 0 0 0 calc( 100vw * 10 / 1920); // 0 0 0 .625rem;
                    }

                    &>i.pipe::before {
                       content: "|";
                       padding: 0 calc( 100vw * 6 / 1920) 0 0;  // 0 .375rem 0 0;
                    }
                }

                & div.aboutus {
                    display: none;
                    z-index: -1;
                    background-color: #fff;
                }

                & div.service-in-header {
                    display: none;
                    z-index: -1;
                    background-color: #fff;
                }

                & div.aboutmaterials {
                    display: none;
                    z-index: -1;
                    background-color: #fff;
                }

                &.hasArrow:hover {

                    &>a {
                        color: #186eb6;
                        &>i::before {
                            content: "\f077"
                        }
                    }
                }

                &:hover {
                    &>a {
                        color: #186eb6;
                    }
                }

                &:last-child {
                    width: calc(100vw * 120 / 1920);
                    display: flex;
                    justify-content: flex-start;
                }
            
            }
        }

        &>.mobileMenu {
            display: none;
        }
    }

    &.mobileMenubar {
        display: none;
        transition: all 1s;
    }

    .disable {
        color: #a9a9a9dd !important;
        cursor: not-allowed;

        & a {
            color: #a9a9a9dd !important
        }
    }

}

.allToWhite {
    &>ul {
        &>li {
            &>a {
                color: white;
            }
        }
    }
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 991.98px) {
    .header {
        padding: calc( 100vw * 20 / 390); // 1.125rem 1.5rem 1.125rem 1.125rem;

        &>a {
            width: calc( 100vw * 150 / 390); // 9.375rem;
            height: calc( 100vw * 40 / 390); // 5rem;
            // line-height: calc( 100vw * 80 / 390); // 5rem;

            &>.logo {
                width: 100%;
                height: 100%;
            }
        }

        &>div.mobileMenu {
            width: calc( 100vw * 28 / 390); // 1.875rem;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            
            &>span.menuIcon {
                width: calc( 100vw * 28 / 390); // 1.75rem;
                height: calc( 100vw * 10 / 390); // .625rem;
                display: inline-block;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
            }
        }


        &>div.tabs {
            display: none;
        }

        &>.mobileMenubar {
            display: block;
        }
        
        &>.backdrop, &>.lr_box {
            display: none;
        }
    
        &>.mobileMenubar {
            display: block;
        }
    }

}
