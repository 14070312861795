.titlebar {
  height: auto;
  padding: 1.875vw 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titlebar > .backToHome a {
  font-size: 0.7291666667vw;
  font-weight: 700;
  line-height: 1.0416666667vw;
  letter-spacing: 0em;
  color: #FAFAFA;
}
.titlebar > .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titlebar > .title > span {
  display: inline-block;
  font-size: 2.0833333333vw;
  font-weight: 700;
  line-height: 3.0208333333vw;
  text-align: center;
  color: #FAFAFA;
}
.titlebar > .title > .icon {
  display: inline-block;
  width: 3.125vw;
  height: 0.2083333333vw;
  margin-top: 8px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}

@media (max-width: 991.98px) {
  .titlebar {
    padding: 9.2307692308vw 0 0 0;
  }
  .titlebar > .backToHome a {
    font-size: 3.5897435897vw;
    line-height: 5.1282051282vw;
  }
  .titlebar > .title > span {
    font-size: 8.7179487179vw;
    line-height: 14.8717948718vw;
  }
  .titlebar > .title > .icon {
    width: 15.3846153846vw;
    height: 1.0256410256vw;
  }
}/*# sourceMappingURL=index.module.css.map */