.itemCard {
  position: relative;
  width: 21.875vw;
  height: 13.5416666667vw;
  background-position: 50% 95%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1.0416666667vw;
  transition: all 0.2s;
  cursor: pointer;
  overflow: hidden;
}
.itemCard::before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, black 100%);
}
.itemCard > .title {
  position: absolute;
  left: 8%;
  bottom: 20%;
  color: white;
  font-size: 1.0416666667vw;
  font-weight: 700;
  transition: all 0.2s;
}
.itemCard > .subtitle {
  position: absolute;
  left: 8%;
  bottom: 10%;
  color: white;
  font-size: 0.8333333333vw;
  font-weight: 700;
  transition: all 0.2s;
}
.itemCard:hover {
  transform: translateY(-4%) scale(1.02);
}
.itemCard:hover > .title {
  transform: translateY(-8%) scale(1.08);
}
.itemCard:hover > .subtitle {
  transform: translateY(-30%) scale(1.08);
}

@media screen and (max-width: 991.98px) {
  .itemCard {
    width: 89.7435897436vw;
    height: 56.4102564103vw;
    border-radius: 5.1282051282vw;
  }
  .itemCard > .title {
    font-size: 5.1282051282vw;
  }
  .itemCard > .subtitle {
    font-size: 4.1025641026vw;
  }
}/*# sourceMappingURL=index.module.css.map */