.bookingWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    z-index: 2;
    height: 50%;
    padding: calc(100vw * 40 / 1920) 0;
    padding-top: calc(100vw * 80 / 1920);
    background-position: center top;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #ffcc01;

    & .booking {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 0 calc(100vw * 40 / 1920) calc(100vw * 154 / 1920); // 2.5rem 0 0 9.625rem;

        &>.bookingTitlebar {
            // min-height: calc(100vw * 60 / 1920); // 3.75rem;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            & span.title {
                font-size: calc(100vw * 40 / 1920); // 2.5rem;
                line-height: calc(100vw * 58 / 1920); // 3.625rem;
                font-weight: 700;
                color: #000;
            }

            & span.subtitle {
                font-size: calc(100vw * 20 / 1920); // 2.5rem;
                line-height: calc(100vw * 40 / 1920); // 3.625rem;
                font-weight: 700;
                color: #000;
            }

            & span.icon {
                width: calc(100vw * 60 / 1920); // 3.75rem;
                height: calc(100vw * 4 / 1920); // .25rem;
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        & .bookingDetail {
            min-height: calc(100vw * 90 / 1920); // 5.625rem;
            padding: calc(100vw * 48 / 1920) 0 calc(100vw * 64 / 1920) 0; // 3rem 0 4rem 0;
            position: relative;
            // font-size: calc(100vw * 20 / 1920); // 1rem;
            // line-height: calc(100vw * 30 / 1920); // 1.875rem;
            // font-weight: 500;
            color: #000;
            // letter-spacing: 2%;

            font-size: calc(100vw * 16 / 1920); // 1rem;
            line-height: calc(100vw * 30 / 1920); // 1.875rem;
            font-weight: 500;
            letter-spacing: 2%;
        }

        & .bookingBtn {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & [class*="buttonWithScale"] {
                width: calc(100vw * 200 / 1920); // 12.5rem;
                height: calc(100vw * 50 / 1920); // 3.125rem;
                background: #000;

                & a {
                    color: white;
                }

                &:hover {
                    & a {
                        color: #000;
                    }

                    border: calc(100vw * 1 / 1920) solid #000; // .0625rem solid white;
                    background: none;
                    transform: scale(1.1);
                }
            }

            & [class*="buttonWithScale"] {
                margin: 0 calc(100vw * 1 / 1920) 0 0; // 0 1.75rem 0 0;

                &:last-child {
                    margin-left: calc(100vw * 30 / 1920);
                }
            }
        }
    }

    & .ppfPic {
        position: relative;

        & img {
            width: 100%;
            // height: 100%;
            height: auto;
            position: absolute;
            // top: 0;
            right: 0;
            bottom: 10%;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .bookingWrapper {
        grid-template-columns: auto;
        grid-template-rows: repeat(4, 1fr);
        width: calc(100vw * 390 / 390);
        height: calc(100vw * 900 / 390);
        padding: calc(100vw * 40 / 390) calc(100vw * 52 / 390); // 2.5rem 9.625rem;
        background-size: cover;

        & .booking {
            position: relative;
            display: flex;
            justify-content: center;
            padding: 0;

            &>.bookingTitlebar {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: auto;

                & span.title {
                    font-size: calc(100vw * 40 / 390); // 2.5rem;
                    line-height: calc(100vw * 58 / 390); // 3.625rem;
                    font-weight: 700;
                    color: #000;
                    text-align: center;
                }

                & span.subtitle {
                    font-size: calc(100vw * 20 / 390); // 2.5rem;
                    line-height: calc(100vw * 38 / 390); // 3.625rem;
                }

                & span.icon {
                    width: calc(100vw * 60 / 390); // 3.75rem;
                    height: calc(100vw * 4 / 390); // .25rem;
                    display: inline-block;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            & .bookingDetail {
                min-height: calc(100vw * 90 / 390); // 5.625rem;
                padding: calc(100vw * 64 / 390) 0 calc(100vw * 54 / 390) 0; // 3rem 0 4rem 0;
                position: relative;
                font-size: calc(100vw * 16 / 390); // 1rem;
                line-height: calc(100vw * 30 / 390); // 1.875rem;
                font-weight: 500;
                color: #000;
                letter-spacing: 2%;
                position: absolute;
                top: calc(100vw * 344 / 390);
                left: 0;
                right: 0;
            }

            & .bookingBtn {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                position: absolute;
                top: calc(100vw * 600 / 390);
                left: 0;
                right: 0;

                &>[class*="buttonWithScale"] {
                    width: calc(100vw * 200 / 390); // 12.5rem;
                    height: calc(100vw * 50 / 390); // 3.125rem;
                    margin: 0 0 calc(100vw * 30 / 390) 0;

                    &:hover {
                        & a {
                            color: white;
                        }

                        border: calc(100vw * 1 / 390) solid white; // .0625rem solid white;
                        background: none;
                        transform: scale(1.1);
                    }
                }

            }

        }

        & .ppfPic {
            position: relative;

            & img {
                width: 100vw;
                height: auto;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }
}