.toast {
  width: 22.9166666667vw;
  height: auto;
  padding: 1.0416666667vw;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.9375vw;
  overflow: hidden;
  z-index: 15;
  box-shadow: 0 0 8px 6px rgba(0, 0, 0, 0.2);
  background-color: #186eb6;
}
.toast > .pickUpInfo {
  text-align: center;
  color: white;
  margin-top: 1.0416666667vw;
  font-size: 1.0416666667vw;
}
.toast > .promocode {
  text-align: center;
  color: #FFCC01;
  margin-top: 1.0416666667vw;
  font-size: 2.0833333333vw;
}
.toast > .tipsInfo {
  text-align: center;
  color: white;
  font-size: 0.8333333333vw;
}
.toast > .confirmBtn {
  display: flex;
  justify-content: center;
  margin-top: 1.5625vw;
}
.toast > .confirmBtn > button:hover {
  border: 0.0520833333vw solid white;
}
.toast > .confirmBtn > button:hover a {
  color: white;
}

@media (max-width: 991.98px) {
  .toast {
    width: 87.1794871795vw;
    padding: 5.1282051282vw;
    top: 50%;
    border-radius: 4.6153846154vw;
  }
  .toast > .pickUpInfo {
    margin-top: 5.1282051282vw;
    font-size: 5.1282051282vw;
  }
  .toast > .promocode {
    margin-top: 5.1282051282vw;
    font-size: 10.2564102564vw;
  }
  .toast > .tipsInfo {
    font-size: 4.1025641026vw;
  }
  .toast > .confirmBtn {
    margin-top: 7.6923076923vw;
  }
  .toast > .confirmBtn > button:hover {
    border: 0.2564102564vw solid white;
  }
  .toast > .confirmBtn > button:hover a {
    color: white;
  }
}/*# sourceMappingURL=index.module.css.map */