#footer {
    background-color: #F8F8F8;
    position: relative;

    .container {
        background-color: #F8F8F8;
    }

    // @include media-breakpoint-up(xl) {
    //     padding-top: 2rem;
    // }
}

#footer_new1 div.logo img {
    width: auto;
    height: 54px;
}

#footer_accordion {
    padding-top: 0rem;
    background-color: #f9f9f9;

    .card {
        border: none;
        background-color: #f9f9f9;

        .card-header {
            border-bottom: 0;
            padding: 0 22px;
            margin: 0;
            font-size: 1.4rem;
            font-weight: bold;
            background-color: RGBA(240, 240, 240, 1);

            .button {
                width: 100%;
                display: flex;
                padding: 2rem 0.5rem 2rem 0.5rem;
                background-color: RGBA(240, 240, 240, 1);
                border-bottom: 1px solid RGBA(50, 50, 50, 0.5);

                .label {
                    flex: 1;
                }

                .arrow {
                    .arrow1 {
                        display: block;
                    }

                    .arrow2 {
                        display: none;
                    }
                }

                &.collapsed {
                    .arrow {
                        .arrow1 {
                            display: none;
                        }

                        .arrow2 {
                            display: block;
                        }
                    }
                }
            }
        }

        .card-body {

            background-color: RGBA(240, 240, 240, 1);

            .content {
                font-size: 1.2rem;
                color: #676767;
                font-weight: 600;

                i {
                    padding: 0.5rem;
                }

                ul {
                    list-style: none;
                    text-decoration: none;
                    padding: 0;
                }
            }

            .follow {
                .content {
                    display: flex;

                    .icon {
                        font-size: 0.8rem;
                        margin-left: 0.5rem;

                        &:nth-child(1) {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.footer {
    .container {
        margin: 0 auto;
    }

    .sections {
        display: flex;
    }

    .separator {
        width: 100%;
        max-width: 1200px;
        border-bottom: 2px solid #e0e0e0;
        margin-top: 1rem;
    }

    .section {
        height: 21.375rem;
        border-right: 0px solid #e0e0e0;
        margin: 0rem;
        font-weight: bold;
        padding-left: 1rem;
        padding-right: 1rem;

        &:last-child {
            border-right: none;
            margin-right: 0;
        }

        .title {
            font-size: 1.625rem;
            font-weight: bold;
            margin-bottom: 1rem;
            color: #333;

        }

        .content {
            font-size: 1rem;
            color: black;

            i {
                padding: 0.5rem;
            }

        }
    }

    ul {
        list-style: none;
        text-decoration: none;
        padding: 0;

        li {
            padding: 0.25rem;
            color: #333;

            .nav-link {
                padding: 0;
                text-decoration: none;
                color: #333;

                // &:hover {
                //     color: #a7a7a7 !important;
                // }
            }

            a {
                color: #333;
                text-decoration: none;
            }
        }
    }

    .follow {
        .content {
            display: flex;
            flex-direction: column;

            .icon {
                margin-top: .875rem;
                width: 6.25rem;
                text-align: center;

                &:first-child {
                    margin-top: 2.125rem;
                }

                img {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
    }

    .common {
        .copyright {
            height: 5rem;
            display: flex;
            // justify-content: space-around;
            justify-content: space-between;
            align-items: center;
            // padding: 0 96px 0 5rem;


            .logo {
                img {
                    width: 9.375rem;
                    // width: auto;
                    // height: 9.375rem;
                }
            }

            .content {
                margin-top: 0.5rem;
                font-size: 0.75rem;

                & span {
                    font-weight: 700;
                }
            }

            .policy {
                span {
                    font-size: 0.75rem;
                    margin: 0 1.25rem;
                    margin-top: 0.5rem;
                }

                &>span:last-child {
                    margin-right: 0;
                }
            }

        }
    }


}

#footer_new1 {
    //margin: 2rem auto 2rem auto;
    padding: 2rem 20px 1rem 20px;
    background-color: white;
    max-width: 100% !important;
    padding: 2rem;
}

#footer_new1 {
    min-height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 2rem;
}


#footer_new1 div.logo-slogan {
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    width: 250px;
    height: 80px;

}


@media screen and (max-width: 767px) {
    #footer_new1 {
        flex-direction: column;
        background-color: white;
    }

    #footer_new1 div.logo-footer-line {
        background-repeat: no-repeat no-repeat;
        background-size: contain;
        width: 116px;
        height: 20px;
        margin-top: 12px;
    }

    #footer_new1 div.logo-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        min-height: 120px;
    }

    #footer_new1 div.logo {
        flex: 1 1 50%;
        height: 80px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-align: center;
    }

    #footer_new1 div.logo img {
        width: auto;
        height: 48px !important;
    }

}

@media screen and (min-width: 768px) and (max-width: 999px) {
    #footer_new1 {
        flex-direction: row;
    }

    #footer_new1 div.logo-footer-line {

        background-repeat: no-repeat no-repeat;
        background-size: contain;
        width: 10px;
        height: 80px;
    }

    #footer_new1 div.logo-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        min-height: 120px;
    }

    #footer_new1 div.logo {
        flex: 1 1 50%;
        height: 60px;
        padding-top: 6px;
        padding-bottom: 6px;
        text-align: center;
    }

    #footer_new1 div.logo img {
        width: auto;
        height: 54px;
    }
}

@media screen and (min-width: 1000px) {
    #footer_new1 {
        flex-direction: row;
    }

    #footer_new1 div.logo-footer-line {
        background-repeat: no-repeat no-repeat;
        background-size: contain;
        width: 10px;
        height: 80px;
        margin: 0 12px;
    }

    #footer_new1 div.logo-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        background-position: center center;
    }

    #footer_new1 div.logo {
        background-repeat: no-repeat no-repeat;
    }
}



#footer_new2 {
    padding: 3.25rem 10rem;

    // @media screen and (max-width: 1199px) {
    //     padding: 2rem 0rem;
    // }

    background-color: RGBA(240, 240, 240, 1);
}



#footer_new2 div.row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#footer_collapse_2,
#footer_new2 {
    .content {
        ul {
            margin-top: 3.125rem;

            li {
                display: flex;
                align-items: center;
                margin-top: 1.125rem;

                >img {
                    margin-right: 1.25rem;
                    margin-top: 0.2rem;
                }
            }
        }
    }
}

#footer_accordion div.new_follow {
    padding: 2rem 1rem 2rem 1rem;
    text-align: center;
    background-color: RGBA(240, 240, 240, 1);
}

#footer_accordion div.new_follow h3 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

#footer_accordion div.new_follow div.follow_group {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

#footer_new3 {
    padding: 2rem auto 2rem auto;
    background-color: RGBA(222, 222, 222, 1);
    position: relative;
    padding: 0 10rem;

    .back-to-top {
        width: 5rem;
        height: 5rem;
        background-color: #333333;

        position: absolute;
        top: -3.8rem;
        right: 2.875rem;

        border-radius: 2.5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        box-shadow: 0 0 .5rem rgba($color: black, $alpha: .6);
        transition: all .3s;

        & a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;

            & span {
                position: absolute;
                top: 20%;
                transition: all .2s;
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.625rem;
                letter-spacing: 0em;
                text-align: left;

            }

            & i {
                position: absolute;
                bottom: 20%;
                transition: all .4s;
            }

            &:hover {

                & span {
                    top: 24%;
                }

                & i {
                    bottom: 24%
                }

                & * {
                    color: white;

                }
            }
        }

        &:hover {
            box-shadow: 0 0 1rem rgba($color: white, $alpha: 1);
        }

        & * {
            color: #FFCC00;
        }
    }

    .for-mobile {
        width: inherit;
    }
}




// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .footer {

        .for-mobile {
            width: 100%;
            padding: 0 calc(100vw * 30 / 390);
        }

        &>#footer_new3 {
            padding: inherit;
        }

        &>#footer_new2 {
            padding: inherit;

            &>div {
                display: block !important;
            }
        }

        & .row {
            flex-direction: column !important;
        }

        & .section {
            height: 2.25rem;
            // height: auto;
            min-height: 2.25rem;
            overflow: hidden;
            margin: 0 0 1.375rem 0;
            transition: all .4s;

            &>.title {
                font-family: Noto Sans TC;
                font-size: 1.375rem;
                font-weight: 500;
                line-height: 1.875rem;
                letter-spacing: 0em;
                text-align: left;
                border-bottom: .0625rem solid rgba($color: #323232, $alpha: .5);
                color: #333333;

                &::after {
                    content: "\203A";
                    float: right;
                    padding: .25rem 1rem;
                    font-weight: 900;
                    font-size: 2.2rem;
                    transform: rotate(90deg);
                    vertical-align: middle;
                    transition: all .4s;
                }
            }

            &>.content {
                display: none;
                position: absolute;
            }

            &:hover,
            &.hover {
                min-height: 17rem;

                &>.content {
                    display: block;

                    & ul {
                        margin: 0 !important;
                    }
                }

                &>.title {

                    &::after {
                        transform: rotate(270deg);
                        padding: .25rem 1rem;
                    }
                }

                &:nth-child(3) {
                    min-height: 8rem;
                }
            }

            &:nth-child(2) {

                &:hover {
                    // min-height: 9rem; // for 2 items
                    min-height: 13rem; // for 3 items
                }
            }

            &:last-child {
                overflow: visible;
                height: auto;

                &>.title {
                    border-bottom: none;
                    text-align: center;
                    font-family: Noto Sans TC;
                    font-size: 1.375rem;
                    font-weight: 500;
                    line-height: 1.875rem;
                    letter-spacing: 0em;
                    color: #333;

                    &::after {
                        content: none;
                    }
                }

                &>.content {

                    display: flex;
                    flex-direction: row !important;
                    justify-content: center;
                    align-items: center;
                    position: static;

                    &>.follow {
                        display: flex;
                        flex-direction: row !important;

                        &>.content {
                            display: flex;
                            flex-direction: row !important;
                            justify-content: center;
                            align-items: center;

                            &>div:first-child {
                                margin-top: 14px;
                            }
                        }
                    }
                }

                &:hover {
                    min-height: auto;
                }
            }

        }

        & .common {

            & .copyright {
                height: auto;
                min-height: 5rem;
                flex-direction: column;
                padding: 1.75rem 0 1.5rem 0;

                &>.content {
                    padding: .5rem;
                    text-align: center !important;
                    white-space: nowrap;
                }

                &>.policy {
                    text-align: center !important;
                }
            }
        }

    }
}