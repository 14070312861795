@charset "UTF-8";
.popupAD {
  width: 22.9166666667vw;
  height: 33.3333333333vw;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.9375vw;
  overflow: hidden;
  z-index: 15;
  box-shadow: 0 0 8px 6px rgba(0, 0, 0, 0.2);
}
.popupAD > .closeBtn_ad {
  width: 1.4583333333vw;
  height: 1.4583333333vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2%;
  right: 4%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 100%;
}
.popupAD > .closeBtn_ad::before {
  content: "X";
  font-weight: 700;
  font-size: 1.0416666667vw;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popupAD > .bottomPlaceholder {
  width: 100%;
  height: 10%;
  position: absolute;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupAD > .bottomPlaceholder > .downward_icon {
  position: absolute;
  top: 0;
  left: 4%;
  transform: rotate(90deg);
  animation: moveDown 500ms linear 0s infinite alternate;
}
.popupAD > .bottomPlaceholder > .downward_icon::before {
  content: "➠";
  color: white;
  font-size: 1.25vw;
}
.popupAD > .bottomPlaceholder > .placeholderText {
  color: white;
  font-weight: 700;
}

@keyframes moveDown {
  0% {
    top: -10%;
  }
  100% {
    top: 30%;
  }
}
@media (max-width: 991.98px) {
  .popupAD {
    width: 87.1794871795vw;
    height: 123.0769230769vw;
    top: 46%;
    border-radius: 4.6153846154vw;
  }
  .popupAD > .closeBtn_ad {
    width: 7.1794871795vw;
    height: 7.1794871795vw;
  }
  .popupAD > .closeBtn_ad::before {
    font-size: 5.1282051282vw;
  }
  .popupAD > .bottomPlaceholder > .downward_icon::before {
    font-size: 6.1538461538vw;
  }
  .popupAD > .bottomPlaceholder > .placeholderText {
    display: inline-block;
    width: 70%;
    text-align: right;
  }
}/*# sourceMappingURL=index.module.css.map */