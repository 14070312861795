.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    // background-color: rgba($color: #000000, $alpha: .24);
    background: linear-gradient(180deg,rgba(179, 179, 179, 0.44),#001634);
    z-index: 14;
}
