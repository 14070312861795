.titlebar {
    height: auto;
    padding: calc(100vw * 36 / 1920) 0 0 0; // 2.25rem 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>.backToHome {

        & a {
            font-size: calc(100vw * 14 / 1920); // 14px;
            font-weight: 700;
            line-height: calc(100vw * 20 / 1920); // 20px;
            letter-spacing: 0em;
            color: #FAFAFA;
        }
    }

    &>.title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &>span {
            display: inline-block;
            // max-width: calc(100vw * 320 / 1920); // 20rem;
            font-size: calc(100vw * 40 / 1920); // 40px;
            font-weight: 700;
            line-height: calc(100vw * 58 / 1920); // 58px;
            // letter-spacing: 0.1em;
            text-align: center;
            color: #FAFAFA;
        }

        &>.icon {
            display: inline-block;
            width: calc(100vw * 60 / 1920); // 3.75rem;
            height: calc(100vw * 4 / 1920); // .25rem;
            margin-top: 8px;
            // background: url(/src/static/images/line-y&b.svg) center center no-repeat;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .titlebar {
        padding: calc(100vw * 36 / 390) 0 0 0; // 2.25rem 0 0 0;

        &>.backToHome {

            & a {
                font-size: calc(100vw * 14 / 390); // 14px;
                line-height: calc(100vw * 20 / 390); // 20px;
            }
        }

        &>.title {

            &>span {
                // max-width: calc(100vw * 320 / 390); // 20rem;
                font-size: calc(100vw * 34 / 390); // 40px;
                line-height: calc(100vw * 58 / 390); // 58px;
            }

            &>.icon {
                width: calc(100vw * 60 / 390); // 3.75rem;
                height: calc(100vw * 4 / 390); // .25rem;
            }
        }
    }
}