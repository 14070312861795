.popupAD {
    width: calc(100vw * 440 / 1920);
    height: calc(100vw * 640 / 1920);
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: calc(100vw * 18 / 1920);
    overflow: hidden;
    z-index: 15;
    box-shadow:  0 0 8px 6px rgba($color: #000000, $alpha: 0.2);

    &>.closeBtn_ad {
        width: calc(100vw * 28 / 1920);
        height: calc(100vw * 28 / 1920);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 2%;
        right: 4%;
        background-color: rgba($color: #fff, $alpha: 0.9);
        border-radius: 100%;

        &::before {
            // content: "\292C";
            content: "X";
            font-weight: 700;
            font-size: calc(100vw * 20 / 1920);
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &>.bottomPlaceholder {
        width: 100%;
        height: 10%;
        position: absolute;
        bottom: 0;
        // background-color: #186eb6;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;

        &>.downward_icon {
            position: absolute;
            top: 0;
            left: 4%;
            transform: rotate(90deg);
            animation: moveDown 500ms linear 0s infinite alternate;

            &::before {
                // content: "🡆";
                content: "\27A0";
                color: white;
                font-size: calc(100vw * 24 / 1920);
            }
        }

        &>.placeholderText {
            color: white;
            font-weight: 700;
        }
    }
}

@keyframes moveDown {
    0% {
        top: -10%;
    }
    100% {
        top: 30%;
    }
}


// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .popupAD {
        width: calc(100vw * 340 / 390);
        height: calc(100vw * 480 / 390);
        top: 46%;
        border-radius: calc(100vw * 18 / 390);

        &>.closeBtn_ad {
            width: calc(100vw * 28 / 390);
            height: calc(100vw * 28 / 390);

            &::before {
                font-size: calc(100vw * 20 / 390);
            }
        }

        &>.bottomPlaceholder {

            &>.downward_icon {

                &::before {
                    
                    font-size: calc(100vw * 24 / 390);
                }
            }

            &>.placeholderText {
                display: inline-block;
                width: 70%;
                text-align: right;
            }
        }
    }
}