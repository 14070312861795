.itemCard {
    position: relative;
    width: calc(100vw * 420 / 1920);
    height: calc(100vw * 260 / 1920);
    // background-position: top left;
    background-position: 50% 95%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: calc(100vw * 20 / 1920);
    transition: all .2s;
    cursor: pointer;
    overflow: hidden;

    &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(#fff, 0) 60%, rgba(#000, 1) 100%);
    }

    &>.title {
        position: absolute;
        left: 8%;
        bottom: 20%;
        color: white;
        font-size: calc(100vw * 20 / 1920);
        font-weight: 700;
        transition: all .2s;
    }

    &>.subtitle {
        position: absolute;
        left: 8%;
        bottom: 10%;
        color: white;
        font-size: calc(100vw * 16 / 1920);
        font-weight: 700;
        transition: all .2s;
    }

    &:hover {
        transform: translateY(-4%) scale(1.02);

        &>.title {
            transform: translateY(-8%) scale(1.08);
        }

        &>.subtitle {
            transform: translateY(-30%) scale(1.08);
        }
    }
}

// tablets
@media screen and (max-width: 991.98px) {
    .itemCard {
        width: calc(100vw * 350 / 390);
        height: calc(100vw * 220 / 390);
        border-radius: calc(100vw * 20 / 390);

        &>.title {
            font-size: calc(100vw * 20 / 390);
        }

        &>.subtitle {
            font-size: calc(100vw * 16 / 390);
        }
    }
}