.article {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding-top: calc(100vw * 180 / 1920);
    padding-bottom: calc(100vw * 40 / 1920);


    &>.bg {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: left top;
        background-repeat: no-repeat;
    }

    &>.mask {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: rgba(14, 99, 170, 0.8);
    }

    &>.wrapper {
        max-width: 60%;
        min-width: 60%;

        &>.prev {
            display: inline-block;
            padding-bottom: calc(100vw * 8 / 1920);
            color: white;
            cursor: pointer;

            &::before {
                content: '<';
            }

            &:last-child {
                margin-top: calc(100vw * 24 / 1920);
            }
        }

        &>.content {
            margin: calc(100vw * 48 / 1920) 0;
            padding: calc(100vw * 64 / 1920);
            border-radius: calc(100vw * 36 / 1920);
            background-color: white;

            & img {
                width: 66.6% !important;
                // transform: translate(25%, 0);
            }

            & video {
                width: 66.6% !important;
                transform: translate(25%, 0);
            }

            &>.title {
                font-size: calc(100vw * 32 / 1920);
                font-weight: 700;
                font-family: 'Microsoft Yahei'
            }

            &>.subtitle {
                font-size: calc(100vw * 20 / 1920);
                font-weight: 600;
            }

            &>.main {
                margin-top: calc(100vw * 24 / 1920);
                font-size: calc(100vw * 18 / 1920);
                font-weight: 500;
            }

            & p {
                text-align: center;
            }
        }


        &>.recommend {

            &>.holder {
                margin-bottom: calc(100vw * 12 / 1920);
                font-size: calc(100vw * 20 / 1920);
                color: white;
                font-weight: 700;
            }

            &>.rec {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: calc(100vw * 24 / 1920);

                & [class*='itemCard'] {
                    justify-self: center;
                    width: 100%;
                    height: calc(100vw * 240 / 1920);
                }
            }
        }
    }
}

// tablets
@media screen and (max-width: 991.98px) {
    .article {
        padding-top: calc(100vw * 120 / 390);
        padding-bottom: calc(100vw * 40 / 390);


        &>.wrapper {
            max-width: 80%;
            min-width: 80%;

            &>.prev {
                padding-bottom: calc(100vw * 8 / 390);

                &:last-child {
                    margin-top: calc(100vw * 24 / 390);
                }
            }

            &>.content {
                margin: calc(100vw * 32 / 390) 0;
                padding: calc(100vw * 24 / 390);
                border-radius: calc(100vw * 18 / 390);

                & img {
                    width: 100% !important;
                    // transform: translate(0, 0);
                }

                & video {
                    width: 100% !important;
                    transform: translate(0, 0);
                }

                &>.title {
                    font-size: calc(100vw * 24 / 390);
                }

                &>.subtitle {
                    font-size: calc(100vw * 14 / 390);
                }

                &>.main {
                    margin-top: calc(100vw * 24 / 390);
                    font-size: calc(100vw * 16 / 390);
                }

                & p {
                    text-align: center;
                }

                & iframe {
                    width: 100%;
                    height: 100%;
                }
            }


            &>.recommend {

                &>.holder {
                    margin-bottom: calc(100vw * 12 / 390);
                    font-size: calc(100vw * 20 / 390);
                }

                &>.rec {
                    grid-template-columns: repeat(1, 1fr);
                    // grid-template-rows: repeat(3, 1fr);
                    grid-template-rows: auto;
                    column-gap: 0;
                    row-gap: calc(100vw * 24 / 390);

                    & [class*='itemCard'] {
                        height: calc(100vw * 200 / 390);
                    }
                }
            }
        }
    }
}