* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Microsoft JhengHei", "Noto Sans TC", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, "PingFang HK";
  color-scheme: light !important;
  /* for samsung broswer auto dark mode */
  scroll-behavior: smooth;

  -ms-overflow-style: none;
  /* Internet Explorer 10+  */
  scrollbar-width: none;
  /* Firefox */

}

*::-webkit-scrollbar {
  display: none;
  /*  Safari and Chrome  */
}

html,
body {
  width: 100%;
  height: 100%;
}

table {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

/*# sourceMappingURL=index.css.map */

/*# sourceMappingURL=index.css.map */


.view {
  /* width: 85.375rem; */
  width: 100%;
  /* min-height: 400.25rem; */
  margin: 0 auto;
  position: relative;
  z-index: 2;
  /* overflow: hidden; */
}

/* .headerWrapper {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .footerWrapper {
    width: 85.375rem;
    height: 35.25rem;
    position: absolute;
    bottom: 0;
  } */

.noWrap {
  white-space: nowrap !important;
}

.littleIconText {
  color: #FFCC01;
  font-weight: 700;
  font-size: calc(100vw * 28 / 1920);
}

.discount {
  color: red;
  font-weight: 700;
}

.discount::before {
  content: "▶";
}

.discount::after {
  content: "◀";
}


/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .littleIconText {
    color: #FFCC01;
    font-weight: 700;
    font-size: calc(100vw * 14 / 390);
  }
}